import React, { useMemo } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { forwardRef } from "@chakra-ui/react";
import { useField } from "formik";

import * as S from "./styled";
interface Props {
  onSave: (count: number) => void;
  value: string | number;
}

const Input = forwardRef((props, ref) => <S.PassengersBlock {...props} ref={ref} />);

const RenderBlock = ({ title, subTitle, value, onSave }: { title: string; subTitle?: string } & Props) => (
  <S.ChildBlock>
    <div>
      <S.ChildBlockTitle>{title}</S.ChildBlockTitle>
      {subTitle && <S.ChildBlockSubTitle>{subTitle}</S.ChildBlockSubTitle>}
    </div>

    <S.NumberButton>
      <S.ControlPanelButton enabled>
        <button onClick={() => onSave(-1)}>
          <S.CircleIcon className="fas fa-minus" />
        </button>
      </S.ControlPanelButton>
      {value}
      <S.ControlPanelButton enabled>
        <button onClick={() => onSave(+1)}>
          <S.CircleIcon className="fas fa-plus" />
        </button>
      </S.ControlPanelButton>
    </S.NumberButton>
  </S.ChildBlock>
);

const Passengers = ({ avia }: { avia?: boolean }) => {
  const [adults, , helperAdults] = useField({ name: "adults", type: "number", value: 0 });
  const [children, , helperChildren] = useField({ name: "children", type: "number", value: 0 });
  const [infants, , helperInfants] = useField({ name: "infants", type: "number", value: 0 });

  const saveAdult = (increment = 0) => {
    const prevCount = +adults.value;
    const count = prevCount + increment;

    if (count > 0) {
      helperAdults.setValue(count);
    }
  };

  const saveChilds = (increment = 0) => {
    const prevCount = +children.value;
    const count = prevCount + increment;

    if (count >= 0) {
      helperChildren.setValue(count);
    }
  };

  const saveInfants = (increment = 0) => {
    const prevCount = +infants.value;
    const count = prevCount + increment;

    if (count >= 0) {
      helperInfants.setValue(count);
    }
  };

  const count = useMemo(() => Number(adults.value) + Number(children.value), [adults.value, children.value]);
  const label = useMemo(() => {
    let passangersLabel = "";
    if (count === 1) {
      passangersLabel = " взрослый";
    }

    if (count > 1 && count < 5) {
      passangersLabel = " человека";
    }

    if (count >= 5) {
      passangersLabel = " человек";
    }
    return passangersLabel;
  }, [count]);

  return (
    <S.LabelWrapper maxWidth={"150px"}>
      <S.Label>Кол. пассажиров</S.Label>
      <Menu closeOnSelect={false}>
        <MenuButton as={Input}>
          {count} {label}
        </MenuButton>
        <MenuList onClick={(event) => event.preventDefault()}>
          <MenuItem as="div">
            <RenderBlock title="Взрослый" value={adults.value} onSave={saveAdult} />
          </MenuItem>
          <MenuItem as="div">
            <RenderBlock
              title="Детский"
              subTitle={avia ? "от 2 до 12" : "до 13 лет"}
              value={children.value}
              onSave={saveChilds}
            />
          </MenuItem>
          {avia && (
            <MenuItem as="div">
              <RenderBlock title="Младенцы" subTitle="до 2 лет" value={infants.value} onSave={saveInfants} />
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </S.LabelWrapper>
  );
};

export default Passengers;
